.custom-tooltip {
    padding: 8px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(204, 204, 204);
    white-space: nowrap;
    text-align: left;
    p {
        margin-bottom: 4px;
    }
    .label {
        text-align: center;
        font-weight: bold;
    }
}